<template>
	<div class="nav-menu">
		<router-link v-for="(item, i) in products" :key="i" class="routeitem" :to="item.href">
			<div :class="item.iconClass" />
			<span class="name">{{ item.name }}</span>
		</router-link>
		<span class="subtitle" v-if="cashDocumentation.length">Касова дисципліна</span>
		<router-link v-for="(item, i) in cashDocumentation" :key="i + _uid" class="routeitem" :to="item.href">
			<div :class="item.iconClass" />
			<span class="name">{{ item.name }}</span>
		</router-link>
		<span class="subtitle">Iнше</span>
		<router-link v-for="(item, i) in other" :key="i + _uid + _uid" class="routeitem" :to="item.href">
			<div :class="item.iconClass" />
			<span class="name">{{ item.name }}</span>
		</router-link>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import isMobileDevice from '@services/is-mobile-device';

export default {
	name: 'NavMenu',
	computed: {
		...mapGetters({
			root: 'root/props',
			isChiefCashier: 'root/isChiefCashier',
			isCashbookOpened: 'root/isCashbookOpened',
			isCashbookClosing: 'root/isCashbookClosing'
		}),
		products() {
			return [
				isMobileDevice ? { name: 'Головна сторінка', href: '/', iconClass: 'main-page' } : null,
				{ name: 'Переказ Миттєвий', href: '/payment/instant-payout', iconClass: 'instant' },
				// { name: 'Переказ Western Union', href: '/payment/western-union-pay', iconClass: 'westernunion' },
				{ name: 'Комуналка, кредит, податки...', href: '/payment/family', iconClass: 'family' },
				{
					name: 'Поповнення карти',
					href: `/payment/instant-topup`,
					iconClass: 'card'
				},
				{
					name: 'Поповнення мобільного',
					href: '/payment/family?family_bank_mobile_category=true',
					iconClass: 'mobile'
				},
				{ name: 'Договір Нафтогаз', href: '/payment/naftogaz', iconClass: 'naftogaz' },
				{ name: 'Поточні кредити', href: '/payment/credits', iconClass: 'credits' },
				{ name: 'Картки та рахунки NovaPay', href: '/payment/novapay-cards', iconClass: 'novapay' }
			].filter(Boolean);
		},
		salepointsAccess() {
			return this.isChiefCashier && this.root.salepoint.branch === 'Філія № 1 (Київська обл.)';
		},
		cashDocumentation() {
			return [
				...((this.isCashbookOpened || this.isCashbookClosing) && this.isChiefCashier
					? [{ name: 'Поточна Касова Книга', href: `/orders/${this.root.cashbook.id}`, iconClass: 'cashbook' }]
					: []),
				...(this.isChiefCashier
					? [
							{ name: 'Журнал КК, ордерів та актів', href: '/cashbooks', iconClass: 'folders' },
							{ name: 'Документи до архіву', href: '/archive-documents', iconClass: 'box' },
							{ name: 'Підкріплення ПНФП', href: '/refreshments', iconClass: 'lock' }
					  ]
					: []),
				...(this.isChiefCashier && (this.root.inventory_record || this.root.active_inventory_record)
					? [{ name: 'Інвентаризація ТМЦ', href: '/inventory-record', iconClass: 'screen' }]
					: [])
			];
		},
		other() {
			return [
				{ name: 'Заявки на зміну ПІБ', href: '/operation-recipient-claims', iconClass: 'folders' },
				...(this.salepointsAccess ? [{ name: 'Доступ до ПНФП', href: '/salepoints-access', iconClass: 'user' }] : []),
				{ name: 'Журнал заявок Нафтогаз', href: '/naftogaz-applications', iconClass: 'naftogaz-apps' },
				{ name: 'Робочі мiсця касирiв', href: '/workplaces', iconClass: 'workplace' },
				{ name: 'Надходження виручки', href: '/payment/revenue-encashment', iconClass: 'lock' }
			];
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-logo-white: '~@assets/images/icon-logo-white.svg';
$icon-calculator-bold: '~@assets/images/icon-calculator-bold.svg';

$icon-transfer: '~@assets/images/nav-icon-transfer.svg';
$icon-flag: '~@assets/images/nav-icon-flag.svg';
$icon-government: '~@assets/images/nav-icon-government.svg';
$icon-cards: '~@assets/images/nav-icon-cards.svg';
$icon-phone: '~@assets/images/nav-icon-phone.svg';
$icon-docs: '~@assets/images/nav-icon-docs.svg';
$icon-folders: '~@assets/images/nav-icon-folders.svg';
$icon-box: '~@assets/images/nav-icon-box.svg';
$icon-lock: '~@assets/images/nav-icon-lock.svg';
$icon-screen: '~@assets/images/nav-icon-screen.svg';
$icon-naftogaz: '~@assets/images/nav-icon-naftogaz.svg';
$icon-naftogaz-apps: '~@assets/images/nav-icon-naftogaz-apps.svg';
$icon-westernunion: '~@assets/images/nav-icon-westernunion.svg';
$icon-workplace: '~@assets/images/nav-icon-workplace.svg';
$icon-credits: '~@assets/images/nav-icon-credits.svg';
$icon-novapay: '~@assets/images/nav-icon-novapay.svg';
$icon-onboarding: '~@assets/images/nav-icon-onboarding.svg';
$icon-user: '~@assets/images/nav-icon-user.svg';

.nav-menu {
	background: $white;
	height: fit-content;
	border-radius: $border-radius;
	margin-right: 24px;

	span.subtitle {
		display: block;
		font-size: 14px;
		font-family: Proxima Nova Semibold;
		color: $gray-40;
		margin: 24px 24px 12px 24px;
	}
	a.routeitem {
		display: flex;
		align-items: center;
		padding: 12px 0 12px 12px;
		height: 44px;
		text-decoration: none;
		&:last-child {
			margin-bottom: 8px;
		}
		&:first-child {
			margin-top: 8px;
		}
		span.name {
			color: $gray-50;
			padding-right: 24px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
			line-height: 17px;
			font-family: Proxima Nova Semibold;
		}
		& > div {
			margin: 0 12px 0 6px;
			width: 28px;
			height: 28px;
			background-size: 28px 28px;
		}
		div.box,
		div.folders,
		div.lock,
		div.cashbook,
		div.workplace,
		div.naftogaz-apps {
			width: 24px;
			height: 24px;
			background-size: 24px 24px;
		}
		div.instant {
			background: url($icon-transfer);
		}
		div.freepay {
			background: url($icon-flag);
		}
		div.family {
			background: url($icon-government);
		}
		div.card {
			background: url($icon-cards);
		}
		div.mobile {
			background: url($icon-phone);
		}
		div.folders {
			background: url($icon-folders) no-repeat;
		}
		div.box {
			background: url($icon-box) no-repeat;
		}
		div.lock {
			background: url($icon-lock) no-repeat;
		}
		div.screen {
			background: url($icon-screen) no-repeat;
		}
		div.main-page,
		div.cashbook {
			background: url($icon-docs) no-repeat;
		}
		div.naftogaz-apps {
			background: url($icon-naftogaz-apps) no-repeat;
		}
		div.naftogaz {
			background: url($icon-naftogaz);
		}
		div.westernunion {
			background: url($icon-westernunion);
		}
		div.workplace {
			background: url($icon-workplace);
		}
		div.credits {
			background: url($icon-credits);
		}
		div.novapay {
			background: url($icon-novapay);
		}
		div.onboarding {
			background: url($icon-onboarding);
		}
		div.user {
			background: url($icon-user) no-repeat;
			margin-right: 8px;
		}
		&:hover {
			cursor: pointer;
			background: $gray-5;
		}
	}
	@media (max-width: $max-navbar-visibility-width) {
		display: none;
	}
	@include mobile() {
		display: none;
	}
}
</style>
