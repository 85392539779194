const types = {
	limListStopped: 'lim_list_stopped',
	limListPhone: 'lim_list_phone',
	debts: 'debts',
	mbki: 'mbki',
	ubkiScore: 'ubki_score',
	ubkiDebts: 'ubki_debts',
	ubkiCurrentDebts: 'ubki_current_debts',
	ubkiAge: 'ubki_age',
	ubkiLimit: 'ubki_limit',
	ubkiPhone: 'ubki_phone',
	ubkiMfo: 'ubki_mfo',
	ubkiError: 'ubki_error',
	ubkiLudomans: 'ubki_ludomans',
	ubkiDoc: 'ubki_doc',
	ubkiFreeze: 'ubki_freeze',
	ubkiMayBeGone: 'ubki_maybegone',
	ubkiZapyt: 'ubki_zapyt',
	ubkiBankruptcy: 'ubki_bankruptcy',
	ubkiRestract: 'ubki_restract',
	ubkiHistDebts30: 'ubki_hist_debts_30',
	ubkiHistDebts60: 'ubki_hist_debts_60',
	ubkiHistDebts90: 'ubki_hist_debts_90',
	ubkiHistDebts: 'ubki_hist_debts',
	ubkiCred: 'ubki_cred',
	limitProfile: 'limit_profile',
	limitDti: 'limit_dti',
	limitPolicy: 'limit_policy',
	mobOperator: 'mob_operator',
	socialStatus: 'social_status',
	age: 'age',
	amount: 'amount',
	phoneUniqueness: 'phone_uniqueness',
	notResident: 'not_resident',
	financialMonitoring: 'financial_monitoring',
	financialMonitoringPEP: 'financial_monitoring_pep',
	minimumLimitNotReached: 'minimum_limit_not_reached',
	taxpayerIdPhoneMissmatch: 'taxpayer_id_phone_missmatch',
	blackList: 'black_list',
	regionDecline: 'region_decline',
	permanentResidencePermit: 'permanent_residence_permit',
	idcardBadFormat: 'idcard_bad_format',
	badValidityPeriodDocument: 'bad_validity_period_document'
};

const locale = {
	[types.limListStopped]: 'відмова за лім. відомістю',
	[types.limListPhone]: 'відмова за лім. відомістю',
	[types.mbki]: 'відмова за даними МБКІ',
	[types.debts]: 'відмова за заборгованістю',
	[types.ubkiScore]: 'відмова за скорингом УБКІ',
	[types.ubkiDebts]: 'відмова за заборгованістю УБКІ',
	[types.ubkiCurrentDebts]: 'відмова за поточною заборгованістю УБКІ',
	[types.ubkiAge]: 'відмова за віком УБКІ',
	[types.ubkiLimit]: 'відмова за лімітом УБКІ',
	[types.ubkiPhone]: 'відмова за телефоном УБКІ',
	[types.ubkiMfo]: 'відмова за донором УБКІ',
	[types.ubkiError]: 'помилка УБКІ',
	[types.ubkiLudomans]: 'відмова за наявністю в реєстрі лудоманів УБКІ',
	[types.ubkiDoc]: 'відмова за втратою документів',
	[types.ubkiFreeze]: 'відмова за заморозкою',
	[types.ubkiMayBeGone]: 'відмова за наявність в реєстрах зниклих безвісті',
	[types.ubkiZapyt]: 'відмова за кількістю останніх кредитних запитів',
	[types.ubkiBankruptcy]: 'відмова за банкрутством',
	[types.ubkiRestract]: 'відмова за реструктуризацією',
	[types.ubkiHistDebts30]: 'відмова за історічною заборгованістю 30 днів',
	[types.ubkiHistDebts60]: 'відмова за історічною заборгованістю 60 днів',
	[types.ubkiHistDebts90]: 'відмова за історічною заборгованістю 90 днів',
	[types.ubkiHistDebts]: 'відмова за історічною заборгованістю',
	[types.ubkiCred]: 'відмова за кількістю активних кредитів',
	[types.limitProfile]: 'відмова за лімітом',
	[types.limitDti]: 'відмова за лімітом згідно платоспроможності',
	[types.limitPolicy]: 'відмова за лімітом згідно політики',
	[types.mobOperator]: 'відмова за даними моб. оператора',
	[types.socialStatus]: 'відмова за соц. статусом',
	[types.age]: 'відмова за віком',
	[types.amount]: 'відмова за сумою кредиту',
	[types.phoneUniqueness]: 'відмова через неунікальний номер телефону',
	[types.notResident]: 'клієнт не резидент',
	[types.financialMonitoring]: 'клієнт в чорному списку',
	[types.financialMonitoringPEP]: 'ліміт по ПЕП',
	[types.minimumLimitNotReached]: 'мінімальний ліміт не досягнуто',
	[types.blackList]: 'чорний список',
	[types.regionDecline]: 'область прописки',
	[types.permanentResidencePermit]: 'у клієнта посвідка на постійне проживання',
	[types.idcardBadFormat]: 'неправильний формат біометричного паспорта',
	[types.badValidityPeriodDocument]: 'неправильний термін дії паспорта'
};

module.exports = {
	enum: types,
	locale: locale
};
