import BigNumber from 'bignumber.js';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import semver from 'semver';
import { core, http, lookup } from 'novapay-ui';
import handleError from '@services/handle-api-error';
import print from '@services/print';
import router from '@/router';
import { processingHandler, toggles } from '../support';

import { enum as scanTypes } from '@repo/enums/document-scan-types';
import { enum as instruments } from '@repo/enums/operation-instruments';
import { enum as mediums } from '@repo/enums/operation-mediums';
import { enum as creditHoldStatuses } from '@repo/enums/credit-hold-statuses';
import { enum as creditScoringDeclineReasons } from '@repo/enums/credit-scoring-decline-reasons';

const env = window.location.href.includes('snp3.forpost.space') ? 'staging' : 'production';

const createActions = (types, rootTypes) => {
	let actions = {
		score: async (context, payload) => {
			let { paymentType } = router.currentRoute.params;
			let res = await lookup(`/v3/credits/score`, { method: 'POST', data: payload });
			if (res.data?.code === 'OTPCheckRequiredError') {
				if (env === 'production') {
					// Checking pos terminal version to accept OTP
					const posDaemonVersion = context.rootGetters['root/posDaemonVersion'];
					if (!posDaemonVersion) {
						context.commit(
							rootTypes.ERROR_ALERT,
							{
								severity: 'error',
								title: 'Помилка підключення до POS-терміналу',
								text: 'Перезапусти POS-термінал та спробуй ще раз'
							},
							{ root: true }
						);
						return;
					}
					if (!semver.gte(posDaemonVersion, '1.8.2')) {
						context.commit(
							rootTypes.ERROR_ALERT,
							{
								severity: 'info',
								title: 'Цей POS-термінал потрібно оновити',
								text: `Допоможи клієнту з іншого ПК,
								а потім створи заявку на оновлення цього POS-термінала в Service Desk`
							},
							{ root: true }
						);
						return;
					}
				}
				res.actionToResume = {
					actionName: 'score',
					loadingAction: 'score',
					payload
				};
			}
			if (res.data?.code === 'CreditNotAvailableError') {
				let errText = '\nПроведи звичайну оплату';
				if (paymentType === 'credit-scoring') {
					switch (res.data?.params?.decline_reason) {
						case creditScoringDeclineReasons.mbki:
						case creditScoringDeclineReasons.ubkiDebts:
						case creditScoringDeclineReasons.ubkiAge:
							errText = '\nЗапропонуй перевірити кредитний ліміт через 90 днів';
							break;
						default:
							errText = '\nЗапропонуй перевірити кредитний ліміт через місяць';
							break;
					}
				}

				res.data.error = res.data.error + errText;
				if (res.data?.params?.public_message) {
					res.data.error += `\nПричина: ${res.data.params.public_message}`;
				}
				switch (res.data?.params?.decline_reason) {
					case creditScoringDeclineReasons.limListPhone:
					case creditScoringDeclineReasons.phoneUniqueness:
						res.data.error =
							'У кредиті відмовлено. Вказаний номер телефону зафіксований як фінансовий за іншим клієнтом. ' +
							'Замініть телефон та повторіть спробу оформити Посилку в кредит';
						break;
				}
			}
			if (!handleError({ processing: processingHandler })(res, context, 200, types.CREDIT_VALIDATION_ERRORS)) {
				return;
			}
			context.commit(types.CREDIT_SCORE, res.data);
		},
		selectCreditPeriod: async (context, { selectedPeriod, phone }) => {
			let { user } = context.rootState.root.props;
			let { documentForScans } = context.state.props;
			// this is required because we need to take taxpayer id scan and include it in vp.
			// normal vp wouldnt have the taxpayer id scan. so we deactivate the old one with scans
			let data = {};
			let shouldDeactivateScans =
				documentForScans?.scans?.length &&
				!find(documentForScans.scans, (s) => [scanTypes.taxpayerId, scanTypes.digital].includes(s.type));
			if (shouldDeactivateScans) {
				data = {
					scan_ids: documentForScans.scans.map((s) => s.id),
					is_active: false,
					deactivated_by: user.login,
					deactivation_reason: 'need_to_create_credit_vp'
				};
				let res = await http('/v3/novaclients/update-scan-status', { method: 'post', data });
				if (!handleError()(res, context)) {
					return;
				}
			}
			context.commit(types.CREDIT_PERIOD_SELECTED, {
				creditPeriod: selectedPeriod,
				shouldDeactivateScans
			});
		},
		createCredit: async (context, payload) => {
			const { operations = [] } = context.state.props;
			const recipients = operations
				.filter(({ metadata }) => !metadata.is_donation)
				.map(({ clients }) => clients)
				.flat()
				.filter(({ type }) => type === 'recipient');

			const processingHandler = (context, res) => {
				if (res.data.code === 'OperationNotPermittedError') {
					toggles.toggleOperationNotPermitted(context, res.data.params);
					return false;
				}
				let errorAlert = { text: res.data.error, code: res.data.code };
				context.commit(rootTypes.ERROR_ALERT, errorAlert, { root: true });
				return false;
			};

			let validationHandler = (context, res) => {
				const isLimitExceeded = res.data.errors.find(
					(e) => e.dataPath.includes('amount') && e.message.includes('100000')
				);
				if (isLimitExceeded) {
					return context.commit(
						rootTypes.ERROR_ALERT,
						{
							title: 'Максимально можлива до оформлення сума для посилки в кредит 100 000 грн',
							text: 'Проведи звичайну оплату'
						},
						{ root: true }
					);
				}

				return context.commit(types.CREDIT_VALIDATION_ERRORS, res.data.errors);
			};

			let creditRes = await http({
				url: '/v3/credits/new-credit',
				method: 'post',
				data: {
					recipients,
					express_waybills: getExpressWaybills(context),
					...payload.credit
				}
			});

			if (!handleError({ processing: processingHandler, validation: validationHandler })(creditRes, context)) {
				return;
			}

			context.commit(types.CREDIT_CREATED, { credit: creditRes.data });
		},
		printCreditDocuments: async (context) => {
			let { credit } = context.state.props;
			let res = await http({
				url: '/v3/credits/documents',
				method: 'get',
				query: {
					credit_id: credit.id
				}
			});
			if (!handleError()(res, context)) {
				return;
			}
			print(res.data);
		},
		sendCreditDocuments: async (context) => {
			if (env === 'production') {
				// Checking pos terminal version to accept OTP
				const posDaemonVersion = context.rootGetters['root/posDaemonVersion'];
				if (!posDaemonVersion) {
					setTimeout(() => {
						context.commit(
							rootTypes.ERROR_ALERT,
							{
								severity: 'error',
								title: 'Помилка підключення до POS-терміналу',
								text: 'Перезапусти POS-термінал та спробуй ще раз'
							},
							{ root: true }
						);
					});
					return;
				}
				if (!semver.gte(posDaemonVersion, '1.8.2')) {
					setTimeout(() => {
						context.commit(
							rootTypes.ERROR_ALERT,
							{
								severity: 'info',
								title: 'Цей POS-термінал потрібно оновити',
								text: `Допоможи клієнту з іншого ПК,
								а потім створи заявку на оновлення цього POS-термінала в Service Desk`
							},
							{ root: true }
						);
					});
					return;
				}
			}

			let { credit } = context.state.props;
			let res = await http({
				url: '/v3/credits/send-docs-and-otp',
				method: 'post',
				data: {
					credit_id: credit.id
				}
			});
			if (!handleError()(res, context)) {
				return;
			}
			if (env === 'production') {
				toggles.togglePosOtp(context);
			} else {
				const otp = '1111';
				context.commit(types.SET_ACTION_TO_RESUME, {
					...context.state.props.actionToResume,
					payload: {
						...context.state.props.actionToResume.payload,
						otp
					}
				});
				context.commit(types.CLOSE_POS_OTP, { otp });
			}
		},
		signAndPayoutCredit: async (context, { otp, scoreData }) => {
			let { credit, operations, savedDocument, creditScore } = context.state.props;
			let res = await http('/v3/credits/sign-and-create-comfort', {
				method: 'post',
				data: {
					otp,
					id: credit.id,
					hold_ids: creditScore.hold_ids
				}
			});

			if (!handleError({ processing: processingHandler })(res, context)) {
				return;
			}

			let payData = {
				endpoint: '/v3/operations/initialize-pay',
				loadingAction: {
					id: instruments.cash
				},
				ids: operations.map((o) => o.id),
				payer: {
					first_name: scoreData.first_name,
					last_name: scoreData.last_name,
					patronymic: scoreData.patronymic,
					phone: scoreData.phone,
					document_type: scoreData.document_type,
					document_series: scoreData.document_series,
					document_number: scoreData.document_number,
					document_issued_at: scoreData.document_issued_at,
					document_issued_by: scoreData.document_issued_by,
					birth_place: scoreData.birth_place,
					birth_date: scoreData.birth_date,
					is_resident: savedDocument.is_resident,
					document_issued_country: savedDocument.country?.id,
					document_expire_at: scoreData.document_expire_at,
					address: scoreData.address
				},
				instrument: instruments.cash,
				medium: mediums.cashdesk,
				ds_scan_ids: savedDocument.scans.map((s) => s.id),
				total_cash: scoreData.amount,
				credit_id: credit.id
			};
			context.commit(types.SET_CREDIT_FLAG, true);
			context.commit(
				rootTypes.ADD_SNACKBAR,
				{
					title: `Кредит оформлено`,
					variant: 'success'
				},
				{ root: true }
			);
			await context.dispatch('submitPayment', payData);
			await context.dispatch('_payoutCredit', { ...res.data, scoreData });
		},
		_payoutCredit: async (context, { comfort_operation_id, comfort_payment_system_public_id, scoreData }) => {
			let { isPaymentFailed, savedDocument } = context.state.props;
			if (isPaymentFailed) {
				return;
			}
			let processingHandler = (context, res) => {
				context.commit(types.CLOSE_CREDIT);
				context.commit(
					rootTypes.ADD_SNACKBAR,
					{
						title: `Помилка автоматичної видачі кредиту.
							Причина: ${res.data.error}.
							Здійсни виплату кредиту вручну за ідентифікатором: ${comfort_payment_system_public_id}.`,
						variant: 'error'
					},
					{ root: true }
				);
				return false;
			};
			let res = await http(`/v3/operations/payout`, {
				method: 'POST',
				data: {
					ids: [comfort_operation_id],
					recipient: {
						phone: scoreData.phone,
						first_name: scoreData.first_name,
						last_name: scoreData.last_name,
						patronymic: scoreData.patronymic,
						document_type: scoreData.document_type,
						document_series: scoreData.document_series,
						document_number: scoreData.document_number,
						document_issued_at: scoreData.document_issued_at,
						document_issued_by: scoreData.document_issued_by,
						document_issued_country: 'UA',
						birth_date: scoreData.birth_date,
						birth_place: scoreData.birth_place
					},
					is_recipient_agent: false,
					ds_scan_ids: savedDocument.scans.map((s) => s.id),
					instrument: instruments.cash,
					medium: mediums.cashdesk
				}
			});
			if (!handleError({ processing: processingHandler })(res, context)) {
				return;
			}
			context.commit(
				rootTypes.ADD_SNACKBAR,
				{
					title: `Кредит успішно видано`,
					variant: 'success'
				},
				{ root: true }
			);
		},
		lookupCredits: async (context, { query }) => {
			context.commit(types.SET_DETECT_COMMISSION_LOADER, true);
			let res = await lookup(`/v3/credits?query=${query || ''}`, { id: 'lookup-credits' });
			if (res.status === 499) {
				return;
			}
			context.commit(types.SET_DETECT_COMMISSION_LOADER, false);
			if (!handleError()(res, context)) {
				return;
			}
			context.commit(types.SAVED_CREDITS, res.data);
		},
		printCreditReport: async (context, { id }) => {
			let res = await http(`/v3/credits/print-report/${id}`);
			if (!handleError()(res, context)) {
				return;
			}
			print(res.data);
		},
		lookupActiveHolds: async (context, query) => {
			const { operations } = context.state.props;
			let order_ids = operations
				.map(
					(op) => op.metadata?.waybill_info && Object.values(op.metadata?.waybill_info).map(({ order_id }) => order_id)
				)
				.flat()
				.filter(Boolean);
			if (!order_ids.length) {
				return;
			}
			let res = await http(`/v3/credits/holds`, {
				query: {
					...query,
					filters: { ...query.filters, order_id: { in: order_ids }, status: { eq: creditHoldStatuses.active } }
				},
				method: 'GET'
			});
			if (res.status === 200) {
				context.commit(types.SET_CREDIT_HOLDS, res.data);
			}
		},
		checkCreditAvailability: async (context, { phone }) => {
			phone = phone?.replace(/[()-\s_]/g, '').trim();
			if (phone.length < 13) {
				return;
			}

			const creditMinAmount = 1000;
			const allowedTypes = ['PaymentForGood', 'PaymentForAfterPayment'];

			const isNotAllTypesAllowed = !context.state.props.operations.some((o) =>
				allowedTypes.includes(o?.metadata?.type)
			);

			let totalAmount = context.state.props.operations.reduce((acc, op) => {
				return new BigNumber(op.amount)
					.plus(op.payer_commission || 0)
					.plus(acc)
					.toString();
			}, 0);

			if (new BigNumber(totalAmount).lt(creditMinAmount) || isNotAllTypesAllowed) {
				return;
			}

			let res = await http(`/v3/credits/get-guaranteed-limit`, {
				data: { phone, totalAmount, isNotAllTypesAllowed },
				method: 'POST'
			});
			const { isCreditAvailable, amount } = res.data;
			const success = res.status === 200;

			const isLimitAvailable = isCreditAvailable && success && new BigNumber(amount || 0).gte(totalAmount);
			const proposeCreditLimitCalc = success && res.data.proposeCreditLimitCalc;

			context.commit(types.SET_CREDIT_AVAILABLE, { isCreditAvailable, isLimitAvailable, proposeCreditLimitCalc });
		}
	};
	actions.lookupActiveHoldsDebounced = debounce(actions.lookupActiveHolds, 500, { leading: true });
	actions.checkCreditAvailabilityDebounced = debounce(actions.checkCreditAvailability, 500, { leading: true });
	return actions;
};

const createMutations = (types) => ({
	[types.SET_CREDIT_HOLDS]: (state, holds) => {
		state.props = { ...state.props, holds };
	},
	[types.SET_CREDIT_AVAILABLE]: (state, { isCreditAvailable, isLimitAvailable, proposeCreditLimitCalc }) => {
		state.props = { ...state.props, isCreditAvailable, isLimitAvailable, proposeCreditLimitCalc };
	},
	[types.SET_CREDIT_FLAG]: (state, isCreditPayment) => {
		state.props = { ...state.props, isCreditPayment };
	},
	[types.CREDIT_SCORE]: (state, creditScore) => {
		state.props = { ...state.props, creditScore, errors: null };
	},
	[types.CREDIT_VALIDATION_ERRORS]: (state, errors) => {
		state.props = { ...state.props, errors };
	},
	[types.CREDIT_PERIOD_SELECTED]: (state, { creditPeriod, shouldDeactivateScans }) => {
		let update = { ...state.props, creditPeriod };
		if (shouldDeactivateScans) {
			update = {
				...update,
				savedDocument: {
					...state.props.savedDocument,
					scans: null,
					verification_profile_id: null
				},
				documentForScans: {
					...state.props.documentForScans,
					scans: null,
					verification_profile_id: null
				}
			};
		}
		state.props = update;
	},
	[types.CLOSE_CREDIT]: (state) => {
		let total = (state.props.operations || []).reduce((acc, op) => {
			return new BigNumber(op.amount).plus(acc).toString();
		}, 0);
		state.props = {
			...state.props,
			creditScore: null,
			creditPeriod: null,
			credit: null,
			creditProfileId: null,
			creditDocumentUrls: null,
			errors: null,
			verificationRequired: new BigNumber(total).gte(30000)
		};
	},
	[types.CREDIT_CREATED]: (state, { credit }) => {
		state.props = {
			...state.props,
			credit,
			errors: null
		};
	},
	[types.SAVED_CREDITS]: (state, credits) => {
		state.props = { ...state.props, savedCredits: credits };
	}
});

const createComponentStore = (namespace) => {
	const mutations = createMutations(core.createTypes(namespace));
	const actions = createActions(core.getTypes(namespace), core.getTypes('root').namespaced);
	return { mutations, actions };
};

function getExpressWaybills(context) {
	let { operations = [] } = context?.state?.props ?? {};
	const res = operations
		.map((op) => op.metadata?.express_waybills)
		.flat()
		.filter(Boolean);
	return res.length ? res : null;
}

export default createComponentStore;
